import React, { useState, useEffect } from "react";
import { useRouter } from "../../../components";
import { useGet } from "../../../components";
import { Item } from "../../items/item";
import { NotFound } from "../../../components";
import env from "../../../env";
import { useStore } from "../../../store";
import { ErrorPopup, Loading } from "../../../components";
import { useInstagram } from "../../../components";
import { MetaTag } from "../../../components";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { gtm } from "../../../lib/tracking";
import { Helmet } from "react-helmet-async";

const log = (...args) => {
  //console.log(args);
};
const areEqual = (prevProps, nextProps) => {
  return prevProps.pageData === nextProps.pageData;
};
const PageMemo = React.memo(Page, areEqual);

export function ViewPage() {
  const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [data, setData] = useState(null);
  //const path = usePath();
  //log('view page', path);
  const { pathname, Redirect } = useRouter();
  useInstagram();

  const get = useGet();
  log("0. viewpage ready");

  useEffect(() => {
    log("*. start loading");
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_PAGE", payload: data.data });
      dispatch({ type: "SET_MENU", payload: data.menu });
    }
    // eslint-disable-next-line
  }, [data]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />;
  }

  //log(router.pathname);
  //log(get.loading());
  log("1. viewpage component", pageLoaded, get.response);

  const pageData = get.response.data;
  //const menuData = get.response.menu;
  const redirectTo = get.response.redirectTo;

  const cmsOption = {};
  log(pageLoaded, !pageLoaded && !!pageData);
  if (!pageLoaded && pageData) {
    //log(pageLoaded, pageData, menuData)
    log("2. dispatching page & menu");
    setPageLoaded(true);
    setData(get.response);
    document.title = pageData.pageTitle;
    gtm.pageView(pathname, pageData.pageTitle);
    return null;
  } else if (redirectTo) {
    if (redirectTo.indexOf("http") >= 0 && typeof window !== `undefined`) {
      return (window.location.href = redirectTo);
    } else {
      return <Redirect to={redirectTo} />;
    }
  } else if (!pageLoaded && !pageData) {
    setPageLoaded(true);
    setData(get.response);
    return <NotFound />;
  }

  log("3. call rendering memo");
  if (pageData) {
    let projectPage =
      pageData.pageUrl && pageData.pageUrl.includes("/projects/")
        ? "project-page"
        : "";
    let themeColor = cmsUtils.payload(pageData, "ColorTheme", "red");
    let _class = document
      .querySelector("div.body-content")
      .className.replace(/theme-color-.*/g, "");
    document.querySelector("div.body-content").className =
      _class +
      "theme-color-" +
      themeColor +
      (projectPage === "project-page" ? " " + projectPage : "");
    //document.querySelector('div.body-content').className= _class + "theme-color-" + themeColor;
    return <PageMemo pageData={pageData} cmsOption={cmsOption} />;
  } else return <NotFound />;
}

function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  cmsOption.pageData = pageData;
  // document.title = pageData.pageTitle;
  log("4. page rendering", pageData, cmsOption);
  const sectionItems = pageData.sections.map((section, index) => {
    return (
      <Section
        item={section}
        key={section.sectionName + index}
        cmsOption={cmsOption}
      ></Section>
    );
  });

  // const homeStructuredData = {
  //   "@context": "https://schema.org",
  //   "@type": "WebPage",
  //   "@id": utils.site.fullUrl(pageData.pageUrl),
  //   "url": utils.site.fullUrl(pageData.pageUrl),
  //   "name": pageData.pageTitle,
  //   datePublished: utils.date.toDateTime(pageData.datePublished),
  //   dateModified: utils.date.toDateTime(pageData.dateUpdated),
  //   "inLanguage": "en-AU"
  // };

  const webPageStructuredData = {
    "@context":"https://schema.org",
    "@graph": [
      // {
      //   "@type": "Place",
      //   "@id": "https://jaba.com.au/#place",
      //   "geo": {
      //     "@type": "GeoCoordinates",
      //     "latitude": -34.9340673,
      //     "longitude": 138.6068462
      //   },
      //   "hasMap": "https://www.google.com/maps/place/Jaba/@-34.9340673,138.6068462,15z/data=!3m1!5s0x6ab0cedc821f30f9:0x3ef3075640afb451!4m14!1m7!3m6!1s0x6ab0cedb7d5f3f9d:0xf27b6b1fb582fad9!2sJaba!8m2!3d-34.9340673!4d138.6068462!16s%2Fg%2F1tqclbmg!3m5!1s0x6ab0cedb7d5f3f9d:0xf27b6b1fb582fad9!8m2!3d-34.9340673!4d138.6068462!16s%2Fg%2F1tqclbmg?entry=ttu&g_ep=EgoyMDI0MTAxMy4wIKXMDSoASAFQAw%3D%3D",
      //   "address": {
      //   "@type": "PostalAddress",
      //   "streetAddress": "167 Gilles St",
      //   "addressLocality": "Adelaide",
      //   "addressRegion": "SA",
      //   "postalCode": "5000",
      //   "addressCountry": "AU"
      //   },
      // },
      {
        "@type": ["LocalBusiness", "Organization"],
        "@id": "https://jaba.com.au/#organization",
        "name": "JABA",
        "url": "https://jaba.com.au",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "167 Gilles St",
          "addressLocality": "Adelaide",
          "addressRegion": "SA",
          "postalCode": "5000",
          "addressCountry": "AU",
        },
        "logo": "https://jaba.com.au/assets/logos/logo.png",
        // "logo": {
        //   "@type": "ImageObject",
        //   "@id": "https://jaba.com.au/#logo",
        //   "url": "https://jaba.com.au/assets/logos/logo.png",
        //   "contentUrl": "https://jaba.com.au/assets/logos/logo.png",
        //   "caption": "JABA",
        //   "width": "512",
        //   "height": "512",
        //   "image":{
        //     "@id":"https://www.jaba.com.au/#logo"
        //   },
        // },
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday"
          ],
          "opens": "09:00",
          "closes": "17:00"
        },
        "location":{
            "@id":"https://www.jaba.com.au/#place"
          },
          "telephone": "+61-082-322-655",
      },
      {
        "@type": "Website",
        "@id": "https://jaba.com.au/#website",
        "url": "https://jaba.com.au",
        "name": "JABA",
        "publisher": {
          "@id": "https://jaba.com.au/#organization",
        }
      },
      {
        "@type": "WebPage",
        "@id": "https://jaba.com.au/#webpage",
        "url": "https://jaba.com.au/",
        "name": "JABA Web Design Adelaide - PWA, SEO, SEM & Branding",
        datePublished: utils.date.toDateTime(pageData.datePublished),
        dateModified: utils.date.toDateTime(pageData.dateUpdated),
        "inLanguage":"en-AU",
        "about": {
          "@id": "https://jaba.com.au/#organization"
        },
        "isPartOf": {
          "@id": "https://jaba.com.au/#website"
        },
      }
    ]
  }

  const localBusinessStructuredData = {
    "@context":"https://schema.org",
    "@type": "LocalBusiness",
    "name": "JABA",
    "image": "https://jaba.com.au/assets/logos/logo.png",
    "@id": "https://jaba.com.au/#localbusiness",
    "url": "https://jaba.com.au/",
    "telephone": "+61-082-322-655",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "167 Gilles St",
      "addressLocality": "Adelaide",
      "addressRegion": "SA",
      "postalCode": "5000",
      "addressCountry": "AU"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": -34.9340673,
      "longitude": 138.6068462
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      "opens": "09:00",
      "closes": "17:00"
    } 
  }  

  return (
    <>
      <MetaTag data={getMetaTag(pageData)} />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(webPageStructuredData)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(localBusinessStructuredData)}
        </script>
      </Helmet>
      <div className={`cmsPage cmsPage--${pageData.pageTypeCode}`}>
        {sectionItems}
      </div>
    </>
  );
}

function Section(props) {
  log("5. section rendering");
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0) return <></>;

  const subItemsElts = subItems.map((subItem, index) => (
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  ));
  return <>{subItemsElts}</>;
}

function getMetaTag(pageData) {
  //const country = utils.site.isNZ? 'New Zealand' : 'Australia'
  const country = "Australia";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;
  const disableAutoTitle = cmsUtils.payload(pageData, "DisableAutoTitle") || "";

  return {
    title:
      disableAutoTitle && disableAutoTitle === "on"
        ? pageData.pageTitle
        : pageData.pageTitle + " | " + titleSuffix,
    description: cmsUtils.payload(pageData, "Description"),
    keywords: cmsUtils.payload(pageData, "Keyword"),
    heroImageUrl: utils.site.fullUrl(cmsUtils.payload(pageData, "ImageUrl")),
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(pageData.pageUrl),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}
