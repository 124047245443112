const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xl2', 'xl3', 'xl4', 'xxl', 'xxl2'],
  breakpoints: [576, 768, 992, 1200, 1300, 1400, 1500, 1600, 1700]
}

const colors = {
  dkGrayColor : '#444444',
  redColor : '#ED1C24',
  orangeColor : '#F15A29',
  yellowColor : '#F7941D',
  greenColor : '#39B54A',
  aquaColor : '#00A79D',
  blueColor : '#0A7CC9',
  purpleColor : '#6832AD',
  violetColor : '#92278F',
}

const variables = {
  //familyBase: '"fira-sans-condensed",sans-serif',
  //familyHeader: '"fira-sans-condensed",serif',

  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',
  familyButton: '"acumin-pro-condensed",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.dkGrayColor,
  bgColor: 'black',

  ...colors,
  ...overloading
}

export default variables;