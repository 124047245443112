export default {
  videoContainer: {
    position: "relative",
    margin: "auto",
  },
  videolink: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "2",
  },
  playIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1",
    width: "100px",
    height: "100px",
    color: "white",
    cursor: "pointer",
    transition: "all 0.5s ease-out",
    "&:hover": {
      color: "#FFC300",
      transition: "all 0.5s ease-out",
    },
  },
  backImgContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1",
    height: "100%",
  },
};
