import React, { useRef } from "react";
import cmsUtils from "../../cmsUtils";
import utils from "../../../../utils";

import { useRouter } from "../../../../components";

import { handleElementLink } from "../../../../components";
import env from "../../../../env";
import { renderHtml } from "./renderHtml";

//import { Document, Page } from 'react-pdf'
//import { Document, Page } from 'react-pdf/dist/entry.webpack';

export function Html(props) {
  const ver = env.ver;
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const { history } = useRouter();
  //const [ pdfViewPath, setPdfViewPath ] = React.useState(null)
  //console.log('item', item, cmsOption);

  const year = new Date().getFullYear();
  let content = cmsOption.isCmsEdit
    ? cmsUtils.payload(item, "Content")
    : cmsUtils
        .payload(item, "Content")
        .replace(/__year__/g, year)
        .replace(
          "__version__",
          "<a href = '/app' class = 'app_version'> PWA " + ver + "</a>"
        );

  content = content
    .replace(
      /class="aos_effect_up"/gi,
      `class="aos_effect_up" data-aos="fade-up"`
    )
    .replace(
      /class="aos_effect_down"/gi,
      `class="aos_effect_down" data-aos="fade-down"`
    )
    .replace(
      /class="aos_effect_left"/gi,
      `class="aos_effect_left" data-aos="fade-left"`
    )
    .replace(
      /class="aos_effect_right"/gi,
      `class="aos_effect_right" data-aos="fade-right"`
    );

  const cssClass = utils.classNames(
    "cms_item",
    "htmlPanel",
    cmsOption.isCmsEdit && "html",
    item.cssClass || item.anchorName || ""
  );

  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  function handleClick(e) {
    let target = "";
    if (e.target.closest("a")) {
      target = e.target.closest("a").getAttribute("target");
    }
    const processed =
      target === "_blank"
        ? handleElementLink(e.target, null)
        : handleElementLink(e.target);
    //const link = utils.url.getLinkFromElement(e.target);
    //console.log('clicked', e, e.target, processed);

    if (processed.handled) {
      e.preventDefault();
      e.stopPropagation();
    } else if (processed.linkInfo && processed.linkInfo.type === "internal") {
      e.preventDefault();
      e.stopPropagation();
      history.push(processed.linkInfo.url);
    } else {
      //e.preventDefault();
      //e.stopPropagation();
      // setPdfViewPath(utils.site.resourcePath(link))

      if (e.target.closest("a")) {
        let aLink = e.target.closest("a");
        let href = aLink.getAttribute("href") + "";

        if (href.indexOf("#") !== -1) {
          let _href = href.split("#");
          console.log("_href", _href);

          const targetId = _href[1];
          let targetElt = document.querySelector(
            'section[data-cms-item-anchor="' + _href[1] + '"]'
          );

          const runEffect = () => {
            let anchorStatus = targetElt.getAttribute("data-anchor-status");
            if (anchorStatus === "collapse") {
              targetElt.setAttribute("data-anchor-status", "expand");
              targetElt.classList.add("expand");
            } else {
              targetElt.setAttribute("data-anchor-status", "collapse");
              targetElt.classList.remove("expand");
            }
          };

          if (targetElt) {
            const wrapper = aLink.closest("p, div, h1, h2, h3, h4, h5");
            const nextElt = wrapper.nextElementSibling;
            console.log(wrapper, nextElt);

            if (
              nextElt &&
              nextElt.getAttribute("data-cms-item-anchor") === targetId
            ) {
              // already in wrapper
            } else {
              // no wrapper
              wrapper.insertAdjacentElement("afterend", targetElt);
            }

            window.setTimeout(runEffect, 100);

            e.preventDefault();
            e.stopPropagation();
          }
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
  }

  const htmlBlock = useRef(null);
  return (
    <>
      <div
        ref={htmlBlock}
        className={cssClass}
        data-cms-html='true'
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        onClick={(e) => handleClick(e)}
      >
        {renderHtml(content, cmsOption)}
      </div>
    </>
  );
}
