//import {useLayoutEffect} from 'react';
import cmsUtils from "../../../cms/utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";
import Slider from "react-slick";

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    //autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const pageTileElts = pages.map((page, index) => {
    const oddOrEven = index % 2;
    if (oddOrEven === 0) {
      return (
        <Tile
          page={page}
          key={index}
          disableLink={cmsOption.isCmsEdit}
          idx={index}
          slidingSetting={settings}
        ></Tile>
      );
    } else {
      return (
        <TileEven
          page={page}
          key={index}
          disableLink={cmsOption.isCmsEdit}
          idx={index}
          slidingSetting={settings}
        ></TileEven>
      );
    }
  });

  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles__bsyc",
    item.cssClass || item.anchorName || ""
  );

  // useLayoutEffect(() => {
  //   document.addEventListener('aos:in', () => {
  //     console.log("sdkfjsdlkfjsdlkfjsdlfkj");
  //   });
  // }, []);

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.pageTiles}
    >
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const colorTheme =
    page.colorTheme && page.colorTheme === "default" ? "red" : page.colorTheme;
  //const index = props.idx;
  const tileIcons =
    page.tileIcons && page.tileIcons !== "" ? page.tileIcons.split(",") : [];
  const sliderImgs =
    page.bottomImageUrl && page.bottomImageUrl !== ""
      ? page.bottomImageUrl.split("|")
      : [];
  //const sliderImgs = ['https://jaba.stg4.jaba.com.au/sites/newjaba/media/devices/device-theframes-1-1200.png'];
  const slidingSetting = props.slidingSetting;

  if (imageUrl && imageUrl !== "") {
    sliderImgs.unshift(imageUrl);
  }

  return (
    <div css={style.pageTile__tile}>
      <SiteLink css={style.tile__content} to={disableLink ? "" : page.pageUrl}>
        <div
          css={style.tile__container}
          className={`odd__container ${colorTheme}`}
        >
          <div css={style.tile__bg} data-aos='fade-up-right'>
            <Slider {...slidingSetting}>
              {sliderImgs.map((item, index) => (
                <img key={index} src={item} alt='3' />
              ))}
            </Slider>
          </div>

          <div
            className='pageTiles__bsyc__container'
            css={style.tile__contents}
            data-aos='fade-up-left'
          >
            <div css={style.tile__contents__title} className={`${colorTheme}`}>
              {" "}
              {page.pageTitle}{" "}
            </div>
            <div
              className='pageTiles__bsyc__des'
              css={style.tile__contents__small_dec}
            >
              {" "}
              {page.tileDesc}{" "}
            </div>
            <div
              className='pageTiles__bsyc__des'
              css={style.tile__contents__dec}
            >
              {page.description}
            </div>
            {tileIcons.length > 0 && <TileIcons icons={tileIcons} />}
          </div>

          <div css={style.tile__contents__plus}>
            <img src={`/assets/icons/${colorTheme + ".png"}`} alt='1' />
          </div>
        </div>
      </SiteLink>
    </div>
  );
}

function TileEven(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const colorTheme =
    page.colorTheme && page.colorTheme === "default" ? "red" : page.colorTheme;
  //const index = props.idx;
  const tileIcons =
    page.tileIcons && page.tileIcons !== "" ? page.tileIcons.split(",") : [];
  const sliderImgs =
    page.bottomImageUrl && page.bottomImageUrl !== ""
      ? page.bottomImageUrl.split("|")
      : [];
  //const sliderImgs = ['https://jaba.stg4.jaba.com.au/sites/newjaba/media/devices/device-theframes-1-1200.png'];
  const slidingSetting = props.slidingSetting;

  if (imageUrl && imageUrl !== "") {
    sliderImgs.unshift(imageUrl);
  }

  return (
    <div css={style.pageTile__tile}>
      <SiteLink css={style.tile__content} to={disableLink ? "" : page.pageUrl}>
        <div
          css={style.tile__container}
          className={`even__container ${colorTheme}`}
        >
          <div css={style.tile__bg_even} data-aos='fade-up-left'>
            <Slider {...slidingSetting}>
              {sliderImgs.map((item, index) => (
                <img key={index} src={item} alt='3' />
              ))}
            </Slider>
          </div>

          <div
            className='pageTiles__bsyc__container'
            css={style.tile__contents_even}
            data-aos='fade-up-right'
          >
            <div css={style.tile__contents__title} className={`${colorTheme}`}>
              {" "}
              {page.pageTitle}{" "}
            </div>
            <div
              className='pageTiles__bsyc__des'
              css={style.tile__contents__small_dec}
            >
              {" "}
              {page.tileDesc}{" "}
            </div>
            <div
              className='pageTiles__bsyc__des'
              css={style.tile__contents__dec}
            >
              {page.description}
            </div>
            {tileIcons.length > 0 && <TileIcons icons={tileIcons} />}
          </div>

          <div css={style.tile__contents__plus_even}>
            <img src={`/assets/icons/${colorTheme + ".png"}`} alt='1' />
          </div>
        </div>
      </SiteLink>
    </div>
  );
}

function TileIcons({ icons }) {
  return (
    <div css={style.tile__icons}>
      <ul>
        {icons.map((item, index) => (
          <TileIconsItem key={index} item={item} />
        ))}
      </ul>
    </div>
  );
}

function TileIconsItem({ item }) {
  const _item = item.split("|");
  const _text = _item[0];
  const _color = _item[1];

  return (
    <li css={style.dotPointColor} className={`dotPointColor__${_color}`}>
      <span
        className='tile_icon_span'
        style={{ color: "white", textTransform: "uppercase" }}
      >
        {" "}
        {_text}{" "}
      </span>
    </li>
  );
}
