import { variables, mq } from "../../../../cssInJs";

export default {
  siteTypeList: {
    maxWidth: "960px",
    margin: "auto",
    marginBottom: "1.5rem",
  },
  button: mq({
    fontSize: ["14px", null, "18px"],
    marginLeft: "40px",
  }),
  dotPointColor: {
    "&.dotPointColor__red": {
      color: variables.redColor,
    },
    "&.dotPointColor__yellow": {
      color: variables.yellowColor,
    },
    "&.dotPointColor__green": {
      color: variables.greenColor,
    },
    "&.dotPointColor__orange": {
      color: variables.orangeColor,
    },
    "&.dotPointColor__purple": {
      color: variables.purpleColor,
    },
    "&.dotPointColor__indigo": {
      color: variables.violetColor,
    },
    "&.dotPointColor__teal": {
      color: variables.aquaColor,
    },
    "&.dotPointColor__blue": {
      color: variables.blueColor,
    }
  },
};
