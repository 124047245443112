import { mq } from "cssInJs";

export default {
  container: mq({
    //border: '1px solid red',
    //maxWidth: ['1700px'],
    maxWidth: ["100%"],
    paddingLeft: "0px",
    paddingRight: "0px",
    ".row": {
      marginRight: "0px",
      marginLeft: "0px",
    },
    ".col__item": {
      paddingRight: "0",
      paddingLeft: "0",
      alignSelf: "center"
    },
    ".col__item.change__flex__order": mq({
      order: ["0", null, "unset"],
      //order: ["0", null, "unset"],
    }),
    ".col__item.change__flex__order__two": mq({
      order: ["-1", null, "unset"],
      //order: ["0", null, "unset"],
    }),
    ".bannerSize--standard, .bannerSize, .youtubePlayer": mq({
      //height: ['460px']
      aspectRatio: "16/9",
      height: "auto",
    }),

    ".bannerPanel .slick-slider .slick-track": {
      backgroundColor: "transparent",
    },
  }),
  container2: mq({
    //border: '1px solid red',
    //maxWidth: ['1700px'],
    maxWidth: ["100%"],
    paddingLeft: "0px",
    paddingRight: "0px",
    ".row": {
      marginRight: "0px",
      marginLeft: "0px",
    },
    ".col__item": {
      paddingRight: "0",
      paddingLeft: "0",
      alignSelf: "center"
    },
    ".col__item.change__flex__order": mq({
      order: ["0", null, "unset"],
      //order: ["0", null, "unset"],
    }),
    ".col__item.change__flex__order__two": mq({
      order: ["-1", null, "unset"],
      //order: ["0", null, "unset"],
    }),
    ".bannerSize--standard, .bannerSize, .youtubePlayer": mq({
      //height: ['460px']
      aspectRatio: "16/9",
      height: "auto",
    }),

    ".bannerPanel .slick-slider .slick-track": {
      backgroundColor: "transparent",
    },
  }),
  html__wrapper: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    flexDirection: "column",
  },
  html__container: mq({
    //backgroundColor: variables.lightGray,
    //height: '100%',
    //flex: '1 0 auto',
    //position: 'relative',
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    justifyContent: "center",
    maxHeight: ["100%", null, "100%"],
    overflow: "hidden",

    "&.top": { justifyContent: "flex-start" },
    "&.middle": {
      justifyContent: "center",
    },
    "&.bottom": { justifyContent: "flex-end" },
    "&.left": {
      alignItems: "flex-start",
    },
    "&.centre": {
      alignItems: "center",
    },
    "&.right": {
      alignItems: "flex-end",
    },

    "&.check_other_panel_banner, &.check_other_panel_youtube": mq({
      marginTop: ["1rem", null, "unset"],
      height: ["100%", null, "100%"],
    }),
  }),
  html__item: {
    //position: 'absolute',
    width: "100%",
    margin: "unset",
    "&.width__short": {
      maxWidth: "250px",
    },
    "&.width__middle": {
      maxWidth: "400px",
    },
    "&.width__wild": {
      //maxWidth:'100%'
      maxWidth: "620px",
    },
  },
};
