/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './siteTypeListStyle';
import utils from 'utils';
import cmsUtils from '../../cmsUtils';

export function SiteTypeList(props){

    const item = props.item;

    console.log("item", item);

    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'siteTypeList', item.cssClass || item.anchorName || '');

    const _tileIcons = cmsUtils.payload(item, 'TileIcons');
    const tileIcons = (_tileIcons && _tileIcons !== '') ? _tileIcons.split(",") : [];
    const buttonLinkUrl = cmsUtils.payload(item, 'ButtonLinkUrl');

    return(
        <div css={style.siteTypeList} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
              tileIcons.length > 0 &&
              <TileIcons icons = {tileIcons} buttonLinkUrl = {buttonLinkUrl} />
            }
        </div>
    )
}

function TileIcons({icons, buttonLinkUrl}){
    return(
      <div css={style.tile__icons}>
        <ul>
          {
            icons.map((item, index) => 
              <TileIconsItem key={index} item = {item} />
            )
          } 
        </ul>
        {
          (buttonLinkUrl && buttonLinkUrl !== '') &&
          <a className="button" css={style.button} href = {  !buttonLinkUrl.includes("http") ? 'https://' + buttonLinkUrl : buttonLinkUrl } target='_blank' rel="noreferrer noopener"> VISIT WEBSITE </a>
        }
      </div>
    )
  }
  
  function TileIconsItem({item}){
    const _item = item.split("|");
    const _text = _item[0];
    const _color = _item[1];
  
    return(
      <li css={style.dotPointColor} className={`dotPointColor__${_color}`}> 
        <span style={{color:'white', textTransform: 'uppercase'}}> {_text} </span> 
      </li>
    )
  }