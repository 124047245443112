import React, { useState } from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";

export function AccordionPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const bgColor = cmsUtils.payload(item, "BgColor");
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  let bgUrl = null;
  if (imageUrl) bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );
  const cssClass = utils.classNames(
    "cms_item",
    "accordionPanel",
    "contentPanel--",
    item.cssClass || item.anchorName || ""
  );

  const subItemsElts = subItems.map((subItem, index) => (
    <div className='wrapper' key={index}>
      <div className={selected === index ? "accordion show" : "accordion"}>
        <div className='item'>
          <div
            className={selected === index ? "title show" : "title"}
            onClick={() => toggle(index)}
          >
            <h4>{subItem.payloads[0].value}</h4>
            <span className='open_button'></span>
          </div>
          <div className={selected === index ? "content show" : "content"}>
            <div className='content_text'>
              <ParsedString inputString={subItem.payloads[1].value} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <section
        className={cssClass}
        style={{
          backgroundColor: `${bgColor}`,
          backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
        }}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div className='container'>
          <div className='accordion_container'>{subItemsElts}</div>
        </div>
      </section>
    </>
  );
}

function parseString(inputString) {
  const result = [];
  let currentIndex = 0;

  // Helper function to add text
  const addText = (endIndex) => {
    if (endIndex > currentIndex) {
      result.push(inputString.slice(currentIndex, endIndex));
    }
    currentIndex = endIndex;
  };

  // Process link
  const linkMatch = inputString.match(/{([^}]+)}/);
  if (linkMatch) {
    addText(linkMatch.index);
    const linkObject = {};
    linkMatch[1].split(",").forEach((pair) => {
      const [key, value] = pair
        .split(":")
        .map((s) => s.trim().replace(/['"]/g, ""));
      linkObject[key] = value;
    });
    result.push(
      <a key={`link-${currentIndex}`} href={linkObject.link}>
        {linkObject.title || "Link"}
      </a>
    );
    currentIndex += linkMatch[0].length;
  }

  // Process list
  const listMatch = inputString.match(/\(\*([^)]+)\)/);
  if (listMatch) {
    addText(listMatch.index);
    const listItems = listMatch[1]
      .split("*")
      .map((item) => item.trim())
      .filter(Boolean);
    result.push(
      <ul key={`list-${currentIndex}`}>
        {listItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
    currentIndex += listMatch[0].length;
  }

  // Add any remaining text
  addText(inputString.length);
  return result;
}

function ParsedString({ inputString }) {
  const parsedContent = parseString(inputString);
  return <div>{parsedContent}</div>;
}
