/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useMemo } from 'react';
import style from './proposalFormStyle';
import utils from 'utils';
import { Button, ErrorMessage, usePost } from 'components';
import { useForm } from '../../../../form';
import { getProposalFormModel } from './ProposalFormModel';
import env from '../../../../env';
//import { gtm } from '../../../lib/tracking';
import { gtm } from '../../../../lib/tracking';
//import { useState, useMemo } from 'react';

export function ProposalForm(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'proposalForm', item.cssClass || item.anchorName || '');
    const emailReceiver = "";
    const mailchimpApiKey = "";
    const mailchimpAudienceKey = "";
    
    const model = getProposalFormModel({});
    const form = useForm(model, { usePlaceholder: true });
    const [sendingStatus, setSendingStatus] = useState(null);
    const [mailChimpError, setMailChimpError] = useState();
    const post = usePost();

    const mailChimpErrorMemo = useMemo(() => {
        return mailChimpError
    }, [mailChimpError]);

    function onSubmit(e){
        setMailChimpError(null);
        form.validateForm(e, () => {
            const _value = form.getValue();
            const value = { 
                ..._value,
                interestedValues : _value.interested.length > 0 ? _value.interested.toString() : '',
                emailReceiver: emailReceiver,
                mailchimpApiKey : mailchimpApiKey,
                mailchimpAudienceKey : mailchimpAudienceKey
            };
            var files = form.getFiles();
            var formData = new FormData();
            formData.append('jsonData', JSON.stringify(value));
            files.forEach(file => {
                (file.files || []).forEach(uploadFile => {
                formData.append(file.name, uploadFile)
                })
            });

            post.sendFormData(env.apiBase + "/api/contact/sendproposalform", formData);
            setSendingStatus('pending')
        })
    }

    if (post.done() && sendingStatus === 'pending') {
        var value = form.getValue();
        gtm.sendEvent('proposalFormSubmitted', {contactEmail: value.email} )
        
        form.reset(model);
        setSendingStatus(null);
    
        if(post.response.results && (post.response.results.MailChimpError && post.response.results.MailChimpError !== "")){
          setMailChimpError("Mail Chimp Error : " + post.response.results.MailChimpError);
        }

    }

    const render = (name) => form.renderControl(name, null);
    return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <form css={style.contactForm} className="contactForm">
                <div className="form-row">
                    <div className="col-md-6 mb-2">
                        {render('interested')}
                    </div>
                    <div className="col-md-6 mb-2">
                        {render('budget')}
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-4 mb-2">{render('name')}</div>
                    <div className="col-md-4 mb-2">{render('email')}</div>
                    <div className="col-md-4 mb-2">{render('phone')}</div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-2">{render('details')}</div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-2"> {render('attachments')} </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-2">
                        <div className="actions">
                            <Button onClick={onSubmit} status={post.status}> SUBMIT </Button>
                            <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
                            <ErrorMessage errors={post.errors} />
                            <ErrorMessage errors={mailChimpErrorMemo} />
                            {post.done() && <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}>Successfully sent!</span>}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}