/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import style from "./style";
import Slider from "react-slick";
import utils from "../../../utils";
import cmsUtils from "../../../cms/utils/cmsUtils";
import { useParallax } from "site/useParallax/useParallax";

export function RotatingReviewsPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const heightSet = cmsUtils.payload(item, "HeightSet") || "standard";
  const parallaxEffect = cmsUtils.payload(item, "ParallaxEffect") || "standard";

  const cssClass = utils.classNames(
    "cms_item",
    parallaxEffect === "parallax"
      ? "rotatingReviewsPanel_parallax"
      : "rotatingReviewsPanel",
    `bannerSize_notused--${heightSet}`,
    item.cssClass || item.anchorName || ""
  );
  const bgUrl =
    cmsOption.pageOptions && cmsOption.pageOptions.imagePath
      ? utils.site.resourcePath(cmsOption.pageOptions.imagePath)
      : utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));

  const parallaxSetting = {
    cutoffPadding: 10, //px
    paddingHeightRatio: 1,
    stretchToFit: true,
    actionRange: [0.1, 0.9],
    imageRatio: 1080 / 1920,
    panelHeight: "auto",
  };

  const panelRef = React.useRef(null);
  useParallax(panelRef, parallaxSetting);

  const RotatingReviewItems = subItems.map((subItem, index) => {
    const reviewContent = cmsUtils.payload(subItem, "ReviewContent");
    const reviewer = cmsUtils.payload(subItem, "Reviewer");
    const starsImage = cmsUtils.payload(subItem, "StarsImage");
    const textColor = cmsUtils.payload(subItem, "TextColor");
    return (
      <div
        key={index}
        css={style.sliderImageWrapper}
        className='slide-conainer'
      >
        <div css={style.sliderImageWrapper2}>
          <img
            css={style.starsImage}
            key={subItem.itemId}
            alt={"sliderimage-" + index}
            src={starsImage}
            className='starsImage'
          />
          <p css={style.reviewContent} style={{ color: textColor }}>
            {reviewContent}
          </p>
          <h5 css={style.reviewer} style={{ color: textColor }}>
            {reviewer}
          </h5>
        </div>
      </div>
    );
  });

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 4000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  if (parallaxEffect === "parallax")
    return (
      <div
        css={style.rotatingReviewPanel}
        ref={panelRef}
        style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div className='container'>
          <Slider {...settings}>{RotatingReviewItems}</Slider>
        </div>
      </div>
    );
  else
    return (
      <div
        css={style.rotatingReviewPanel}
        style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div className='container'>
          <Slider {...settings}>{RotatingReviewItems}</Slider>
        </div>
      </div>
    );
}
