//import { mq, variables } from 'cssInJs';

export default {
  contactForm: {
    maxWidth: "960px",
    margin: "auto",
    color: "#444",
    "span.req": {
      display: "none",
    },
  },
};
