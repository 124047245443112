import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/websites', title: 'WEBSITES', icon: 'website-icon.png', iconHover: 'website-hover.png' },
    { url: '/online-shops', title: 'ONLINE SHOPS', icon: 'onlineshop-icon.png?v=1', iconHover: 'onlineshop-hover.png?v=1' },
    { url: '/digital-marketing', title: 'SEO & SEM', icon: 'sem-icon.png', iconHover: 'sem-hover.png' },
    { url: '/branding', title: 'BRANDING', icon: 'branding-icon.png', iconHover: 'branding-hover.png' },
    { url: '/contact', title: 'CONTACT', icon: 'contact-icon.png', iconHover: 'contact-hover.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index} ${isCurrent(link.url) ? 'selected' : 'unselected'}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className="bottomMenu">
        {/* <div className="bottomMenu__bar">
          <div className="bottomMenu__line1"></div>
          <div className="bottomMenu__line2"></div>
        </div> */}
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
      {/* <div className="bottomMenuPadding"></div> */}
    </>
  );
}
